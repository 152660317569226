<template>
    <div class="manage-content">
        <div class="manage-head">
            <el-button class="creationBtn" style="margin-left: 10px;" @click="createTrain">创建理论训练</el-button>
        </div>
        <el-table class="table-content" :data="tableData" height="100%" border
                  :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400,borderRight:'unset'}"
                  :header-cell-style="{fontWeight: '500', color: '#14141C', background: '#F3F4FA',height: '60px',borderRight:'unset'}">
            <el-table-column label="理论题库分类" prop="name" align="center" width="200"></el-table-column>
            <el-table-column label="训练名称" prop="train_name" width="200" align="left">
                <template slot-scope="scope">
                    <span class="train-name">{{scope.row.train_name}}</span>
                </template>
            </el-table-column>
            <el-table-column label="训练班级" prop="student_class_name" width="120" align="left"></el-table-column>
            <el-table-column label="训练状态" prop="s_num" width="120" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.status == 0">未开始</span>
                    <span v-if="scope.row.status == 1">进行中</span>
                    <span v-if="scope.row.status == 2">已结束</span>
                </template>
            </el-table-column>
            <el-table-column label="班级人数" prop="t_num" width="120" align="left">
                <template slot-scope="scope">
                    <span>{{scope.row.class_num}}人</span>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="create_time" align="left"></el-table-column>
            <el-table-column label="开始时间" prop="start_time" align="left"></el-table-column>
            <el-table-column label="结束时间" prop="end_time" align="left">
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" style="color: #1122D8" v-if="scope.row.status == 0"
                               @click="startTrain(scope.row)">开始训练
                    </el-button>
                    <el-button type="text" style="color: #1122D8" v-if="scope.row.status == 1"
                               @click="endTrain(scope.row)">结束训练
                    </el-button>
                    <el-button type="text" style="color: #1222D8" :class="scope.row.status == 1?'unable-btn':''"
                               v-if="scope.row.status != 2" @click="editRow(scope.row)">编辑
                    </el-button>
                    <el-button type="text" style="color: #EB6547" @click="delRow(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="adminPages.currentPageNum"
                :page-size="adminPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="adminPages.total"
                @current-change="adminCurrentChange"
        ></el-pagination>
    </div>
</template>

<script>
    import {showTheoryListData, theorytrainStart, theorytrainEnd, theorytrainDel} from '@/utils/apis'

    export default {
        name: "theoryManagement",
        data() {
            return {
                tableData: [],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum
                }
                showTheoryListData(params).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.list
                        this.adminPages.total = res.data.total
                    }
                })
            },
            createTrain() {
                this.$router.push('/trainAdmin/theoryManage/creationTheory')
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getList()
            },
            startTrain(row) {
                theorytrainStart({id: row.id}).then(res => {
                    if (res.code === 200) {
                        this.$message.success('训练开始！');
                        this.getList()
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            endTrain(row) {
                theorytrainEnd({id: row.id}).then(res => {
                    if (res.code === 200) {
                        this.$message.success('训练结束！');
                        this.getList()
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            editRow(row) {
                if (row.status == 1) {
                    this.$message.warning('训练进行中不可编辑')
                    return
                }
                this.$router.push({
                    path:'/trainAdmin/theoryManage/creationTheory',
                    query:{
                        id:row.id
                    }
                })
            },
            delRow(row) {
                if (row.status == 1) {
                    this.$message.warning('训练进行中不可删除')
                    return
                }
                this.$confirm('是否确认删除？', '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    theorytrainDel({id: row.id}).then(res => {
                        if (res.code === 200) {
                            this.$message.success('删除成功！');
                            this.getList()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .manage-content {
        padding: 30px;
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;

        .manage-head {
            margin-bottom: 20px;
            text-align: right;

            .creationBtn {
                /*width: 80px;*/
                height: 36px;
                color: #ffffff;
                background: #1222D8;
                border-color: #1222D8;
                line-height: 0;
                padding: 0 12px;
                border-radius: 10px;
            }
        }

        .table-content {
            flex: 1;
            height: 1%;

            .train-name {
                /*text-overflow: ellipsis;*/
                /*overflow: hidden;*/
                /*white-space: nowrap;*/
            }

            .unable-btn {
                color: #999999 !important;
            }
        }

        .pages-center {
            text-align: center;
            margin-top: 20px;
        }
    }
</style>